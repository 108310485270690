<template>
    <v-container fluid class="ma-0 mb-3 pa-0 pl-2 pt-2">
        <v-row class="justify-space-arround">
            <v-col cols="12">
                <v-card class="default" outlined height="100%">
                    <v-toolbar short flat color="secondary">
                        <v-toolbar-title
                            class="text-subtitle-1 primary--text"
                            v-text="
                                $t('settings.department.itemHeader')
                            "
                        />
                    </v-toolbar>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-text-field
                            v-model="model.title"
                            :label="$t('settings.department.title.label')"
                            :error-messages="errors.collect('title')"
                            data-vv-name="title"
                            v-validate="'required'"
                            spellcheck="true"
                            autofocus
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                        <v-combobox
                            v-model="departmentCategoriesModel"
                            :items="departmentCategoryItems"
                            :label="$t('settings.department.departmentCategories.label')"
                            item-value="id"
                            item-text="title"
                            :error-messages="errors.collect('departmentCategories')"
                            data-vv-name="departmentCategories"
                            v-validate="'required'"
                            multiple
                            chips
                            deletable-chips
                            small-chips
                            @input="$emit('modified', $event)"
                        ></v-combobox>
                        <v-switch
                            v-if="enableTranslation"
                            v-model="model.forcedTranslation"
                            color="primary"
                            :label="$t('settings.forcedTranslation.label')"
                            class="mt-0"
                            @change="$emit('modified', $event)"
                        ></v-switch>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { isUUID } from '@/components/vvt/ModelMixin.js';
export default {
    name: 'SettingsCompanyDepartmentsEditorMask',
    inject: {
        $validator: '$validator',
    },
    props: {
        parentId: Number,
        departmentCategoryItems: Array,
        value: Object,
    },
    i18n: {
        messages: {
            en: require('@/locales/Settings/en.json'),
            de: require('@/locales/Settings/de.json'),
        },
    },
    data() {
        return {
            model: this.value,
        };
    },
    mounted() {
        this.model.companyId = this.parentId;
    },
    computed: {
        enableTranslation() {
            return !isUUID(this.model.id);
        },
        departmentCategoriesModel: {
            get() {
                if (!this.model || !this.model.departmentCategories) {
                    return [];
                }
                return this.departmentCategoryItems.filter(x =>
                    this.model.departmentCategories.includes(x.id)
                );
            },
            set(val) {
                this.model.departmentCategories = val.map(x => x.id);
            },
        },
    },
};
</script>
